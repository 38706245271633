import React, { useState, useEffect } from "react"
import { Link } from 'gatsby'
import { useForm } from "react-hook-form";
import Metatags from "../components/Metatags";
const ContactUSPage = ({ location }) => {

    useEffect(() => {
        if (location.pathname == '/neemcontactop') {
            document.getElementsByTagName('body')[0].classList.remove('menu-overlay');
        }
    }, []);

    const { register, handleSubmit, reset, errors, getValues, formState: {
        isSubmitting
    } } = useForm();

    const [isSubmited, setIsSubmited] = useState(false);

    const hasError = field => errors[field] !== undefined;
    const getError = field => hasError(field) && errors[field].message;
    const API_URL = 'https://5q0p1oxzc4.execute-api.us-east-1.amazonaws.com/dev';
    const onSubmit = async data => {
        try {
            setIsSubmited(true)
            reset();
            data.to = ["nicky@molenkoning.com"];
            data.from = "nicky@ijzersterk-tekenwerk.nl";
            data.subject = "Reactie van de website";
            await fetch(API_URL, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                body: JSON.stringify(data),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            });
            setIsSubmited(false)
        } catch (error) {
            setIsSubmited(false)
            // handle server errors
        }
    };

    const validateService = _ => {
        const values = getValues({ nest: true });
        return (
            values.serviceDetail.filter(v => Boolean(v)).length >= 1 || 'Please select at least one Service'
        );
    };

    const validateEmail = _ => {
        const values = getValues({ nest: true });
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return (re.test(values.email.toLowerCase()) || 'Please enter valid email');
    }

    const validatePhone = _ => {
        const values = getValues({ nest: true });
        const re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
        return (re.test(values.contactNumber.toLowerCase()) || 'Please enter valid Phone number');
    }

    return (
        <section className="contact-us">
            <div className="container">
                <Link className="logo" to="/">
                    <img src="../images/logo.png" alt="" />
                </Link>
                <h1>Neem contact met ons op:</h1>
                <div className="row">
                    <div className="col-sm-12 col-lg-4">
                        <form method="post" onSubmit={handleSubmit(onSubmit)} >
                            <div className="form-group">
                                <label>Naam <span>*</span></label>
                                <input type="text"
                                    className="form-control"
                                    name="name"
                                    disabled={isSubmitting}
                                    id="name"
                                    ref={register({
                                        required: 'Please enter first name',
                                    })}
                                />
                                {hasError("name") && (
                                    <span className="form-error" error={toString(hasError("name"))}> {getError("name")}</span>
                                )}

                            </div>
                            <div className="form-group">
                                <label>Email <span>*</span></label>
                                <input type="text"
                                    className="form-control"
                                    disabled={isSubmitting}
                                    name="email"
                                    id="email"
                                    ref={register({ validate: validateEmail, required: "Please enter email" })}
                                />
                                {hasError("email") && (
                                    <span className="form-error" error={toString(hasError("email"))}> {getError("email")}</span>
                                )}
                            </div>
                            <div className="form-group">
                                <label>Telefoonnummer <span>*</span></label>
                                <input type="text"
                                    className="form-control"
                                    name="contactNumber"
                                    disabled={isSubmitting}
                                    id="contactNumber"
                                    ref={register({
                                        validate: validatePhone,
                                        required: 'Please enter contact number',
                                    })}
                                />
                                {hasError("contactNumber") && (
                                    <span className="form-error" error={toString(hasError("contactNumber"))}> {getError("contactNumber")}</span>
                                )}
                            </div>
                            <div className="form-group">
                                <label>Bericht</label>
                                <textarea className="form-control"
                                    rows="3"
                                    name="description"
                                    disabled={isSubmitting}
                                    id="description"
                                    ref={register}
                                />
                            </div>
                            <button
                                className="btn btn-trt-primary w-100 mt-4"
                                disabled={isSubmitting}
                                type="submit">
                                {!isSubmited ? <span>Verstuur</span> : ''}
                                {isSubmited ? <div
                                    className="spinner-border text-light"
                                    role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> : ''}
                            </button>
                        </form >
                    </div >
                </div >
            </div >
        </section >
    )
}
export default ContactUSPage;